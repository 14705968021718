<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-01-10 11:19:26
 * @FilePath: /beilunchanye_fe_20211208/src/views/haixing.vue
 * @Description: 
-->
<template>
  <div class="home haixing">
    <div v-show="$store.state.animateDelay && $store.state.mapType" class="haixing-wrap haixing-left-wrap"
      :class="{ 'animate__animated': true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue }">
      <!-- 项目概况 -->
      <div class="haixing-item haixing-item-01">
        <div class="haixing-item-title-wrap">
          <div class="haixing-item-title">
            <div class="haixing-item-title-icon"></div>
            <div class="haixing-item-title-name">项目概况</div>
          </div>
          <div class="haixing-item-title-img"></div>
        </div>
        <div class="haixing-item-bottom-line"></div>
        <div class="haixing-item-content">
          <div class="haixing-item-content-data">
            <div class="haixing-item-content-top">
              <div class="haixing-item-content-item">
                <div class="haixing-item-content-item-icon"></div>
                <div class="haixing-item-content-item-data">
                  <div class="haixing-item-content-item-name">建筑面积</div>
                  <div class="haixing-item-content-item-value">
                    <CountUp v-if="data && data.build_area" :delay="1000" :endVal="data.build_area" />
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <div class="haixing-item-content-item">
                <div class="haixing-item-content-item-icon"></div>
                <div class="haixing-item-content-item-data">
                  <div class="haixing-item-content-item-name">层高(米)</div>
                  <div class="haixing-item-content-item-value">
                    <span v-if="data && data.floor_height">{{ data.floor_height }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <!-- <div class="haixing-item-content-item">
                <div class="haixing-item-content-item-icon"></div>
                <div class="haixing-item-content-item-data">
                  <div class="haixing-item-content-item-name">楼板承重(KG)</div>
                  <div class="haixing-item-content-item-value">
                    <CountUp :delay="1000" :endVal="300" />
                  </div>
                </div>
              </div> -->
              <div class="haixing-item-content-item">
                <div class="haixing-item-content-item-icon"></div>
                <div class="haixing-item-content-item-data">
                  <div class="haixing-item-content-item-name">车位数量(个)</div>
                  <div class="haixing-item-content-item-value">
                    <CountUp v-if="data && data.car_num" :delay="1000" :endVal="data.car_num" />
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="haixing-item-content-bottom">
              <div class="haixing-item-content-bottom-title">海星医药健康创新园（C区）</div>
              <div class="haixing-item-content-bottom-describe">位于海淀区永丰538号，东临永 丰产业基地，西临火箭军装备研究院，总占地面积约168亩，总建面6.5万平方米。
              </div>
              <div class="haixing-item-content-bottom-subtitle">产业定位：</div>
              <div class="haixing-item-content-bottom-describe">“生物医药”为核心，“实验检测/临床诊断/药品及 生物制品认证“为抓手。</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 区域位置 -->
      <div class="haixing-item haixing-item-02">
        <div class="haixing-item-title-wrap">
          <div class="haixing-item-title">
            <div class="haixing-item-title-icon"></div>
            <div class="haixing-item-title-name">区域位置</div>
          </div>
          <div class="haixing-item-title-img"></div>
        </div>
        <div class="haixing-item-bottom-line"></div>
        <div class="haixing-item-content">
          <div class="haixing-item-content-data">
            <div class="haixing-item-content-data-wrap">
              <div class="haixing-item-content-data-item">
                <div class="haixing-item-content-data-icon"></div>
                <div class="haixing-item-content-data-text">
                  <b>区域：</b>
                  <span>海淀永丰片区，五环六环之间</span>
                </div>
              </div>
              <div class="haixing-item-content-data-item">
                <div class="haixing-item-content-data-icon"></div>
                <div class="haixing-item-content-data-text">
                  <b>位置：</b>
                  <span>永丰产业基地西侧</span>
                </div>
              </div>
              <div class="haixing-item-content-data-item">
                <div class="haixing-item-content-data-icon"></div>
                <div class="haixing-item-content-data-text">
                  <b>高速：</b>
                  <span>G6、G7、五环、六环</span>
                </div>
              </div>
              <div class="haixing-item-content-data-item">
                <div class="haixing-item-content-data-icon"></div>
                <div class="haixing-item-content-data-text">
                  <b>公路：</b>
                  <span>北清路 翠湖北路 永丰路 上庄路</span>
                </div>
              </div>
              <div class="haixing-item-content-data-item">
                <div class="haixing-item-content-data-icon"></div>
                <div class="haixing-item-content-data-text">
                  <b>地铁：</b>
                  <span>16号线永丰站（1公里）</span>
                </div>
              </div>
              <div class="haixing-item-content-data-item">
                <div class="haixing-item-content-data-icon"></div>
                <div class="haixing-item-content-data-text">
                  <b>公交：</b>
                  <span>365、438、449、512、570、专128路</span>
                </div>
              </div>
            </div>
            <div class="haixing-item-content-data-img"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="$store.state.animateDelay && $store.state.mapType" class="haixing-wrap haixing-right-wrap"
      :class="{ 'animate__animated': true, 'animate__fadeInRightBig': $store.state.showDataValue, 'animate__fadeOutRightBig': !$store.state.showDataValue }">
      <!-- 招商概况 -->
      <div class="haixing-item haixing-item-03">
        <div class="haixing-item-title-wrap">
          <div class="haixing-item-title">
            <div class="haixing-item-title-icon"></div>
            <div class="haixing-item-title-name">招商概况</div>
          </div>
          <div class="haixing-item-title-img"></div>
        </div>
        <div class="haixing-item-bottom-line"></div>
        <div class="haixing-item-content">
          <div class="haixing-item-content-data">
            <div class="haixing-item-content-top">
              <div class="haixing-item-content-top-item">
                <div class="haixing-item-content-top-item-chart">
                  <div id="OneChart" :style="{ width: '120px', height: '120px' }"></div>
                </div>
                <div class="haixing-item-content-top-item-chart-data">
                  <div class="haixing-item-content-top-item-chart-data-value">
                    <CountUp v-if="data && (data.nm1_full_area && data.nm1_rent_area)" :delay="1000"
                      :endVal="data.nm1_rent_area / data.nm1_full_area * 100" />
                    <span v-else>-</span>
                    %
                  </div>
                  <span class="haixing-item-content-top-item-chart-data-value-name">出租率</span>
                </div>
                <div class="haixing-item-content-top-item-chart-name">1号楼</div>
                <div class="haixing-item-content-top-item-chart-line"></div>
              </div>
              <div class="haixing-item-content-top-item">
                <div class="haixing-item-content-top-item-chart">
                  <div id="TwoChart" :style="{ width: '120px', height: '120px' }"></div>
                </div>
                <div class="haixing-item-content-top-item-chart-data">
                  <div class="haixing-item-content-top-item-chart-data-value">
                    <CountUp v-if="data && (data.nm2_full_area && data.nm2_rent_area)" :delay="1000"
                      :endVal="data.nm2_rent_area / data.nm2_full_area * 100" />
                    <span v-else>-</span>
                    %
                  </div>
                  <span class="haixing-item-content-top-item-chart-data-value-name">出租率</span>
                </div>
                <div class="haixing-item-content-top-item-chart-name">2号楼</div>
                <div class="haixing-item-content-top-item-chart-line"></div>
              </div>
              <div class="haixing-item-content-top-item">
                <div class="haixing-item-content-top-item-chart">
                  <div id="ThreeChart" :style="{ width: '120px', height: '120px' }"></div>
                </div>
                <div class="haixing-item-content-top-item-chart-data">
                  <div class="haixing-item-content-top-item-chart-data-value">
                    <CountUp v-if="data && (data.nm3_full_area && data.nm3_rent_area)" :delay="1000"
                      :endVal="data.nm3_rent_area / data.nm3_full_area * 100" />
                    <span v-else>-</span>
                    %
                  </div>
                  <span class="haixing-item-content-top-item-chart-data-value-name">出租率</span>
                </div>
                <div class="haixing-item-content-top-item-chart-name">3号楼</div>
                <div class="haixing-item-content-top-item-chart-line"></div>
              </div>
            </div>
            <div class="haixing-item-content-bottom">
              <div class="haixing-item-content-bottom-title">
                <span>楼号</span>
                <span>可租赁面积</span>
                <span>已租赁面积</span>
                <span>未租赁面积</span>
              </div>
              <div class="haixing-item-content-bottom-content">
                <div class="haixing-item-content-bottom-item">
                  <span>1号楼</span>
                  <span v-if="data && data.nm1_full_area">{{ data.nm1_full_area }}</span>
                  <span v-else>-</span>
                  <span v-if="data && data.nm1_rent_area">{{ data.nm1_rent_area }}</span>
                  <span v-else>-</span>
                  <span v-if="data && data.nm1_notrent_area">{{ data.nm1_notrent_area }}</span>
                  <span v-else>-</span>
                </div>
                <div class="haixing-item-content-bottom-item">
                  <span>2号楼</span>
                  <span v-if="data && data.nm2_full_area">{{ data.nm2_full_area }}</span>
                  <span v-else>-</span>
                  <span v-if="data && data.nm2_rent_area">{{ data.nm2_rent_area }}</span>
                  <span v-else>-</span>
                  <span v-if="data && data.nm2_notrent_area">{{ data.nm2_notrent_area }}</span>
                  <span v-else>-</span>
                </div>
                <div class="haixing-item-content-bottom-item">
                  <span>3号楼</span>
                  <span v-if="data && data.nm3_full_area">{{ data.nm3_full_area }}</span>
                  <span v-else>-</span>
                  <span v-if="data && data.nm3_rent_area">{{ data.nm3_rent_area }}</span>
                  <span v-else>-</span>
                  <span v-if="data && data.nm3_notrent_area">{{ data.nm3_notrent_area }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业列表 -->
      <div class="haixing-item haixing-item-04">
        <div class="haixing-item-title-wrap">
          <div class="haixing-item-title">
            <div class="haixing-item-title-icon"></div>
            <div class="haixing-item-title-name">企业列表</div>
          </div>
          <div class="haixing-item-title-img"></div>
        </div>
        <div class="haixing-item-bottom-line"></div>
        <div class="haixing-item-content">
          <div class="haixing-item-content-data" v-if="list.length > 0">
            <div v-for="(item, index) in list" :key="index" class="haixing-item-content-data-item"
              :class="{ 'haixing-item-content-data-active': (itemData && itemData.company_name == item.company_name) }"
              @click="onItem(item, index)">
              <div class="haixing-item-content-data-icon"></div>
              <div class="haixing-item-content-data-name">{{ item.company_name }}</div>
            </div>
          </div>
          <div class="haixing-nodata" v-else>当前暂无列表数据</div>
        </div>
      </div>
    </div>
    <CompanyDetails name="haixing" :visible="visible" :data="itemData"
      :onClose="() => { itemData = null; visible = !visible }" />
  </div>
</template>

<script>

import { getCompanyList } from '@/api/zgc';
import { getHaiXingData } from '@/api';
import CountUp from "vue-countup-v2";
import CompanyDetails from "@/components/companyDetails";

export default {
  name: 'HaiXing',
  data() {
    return {
      data: null,
      list: [],
      visible: false,
      itemData: null
    }
  },
  watch: {

  },
  computed: {

  },
  components: {
    CountUp,
    CompanyDetails
  },
  methods: {
    initScale() {
      window.onload = function () {
        adjust()
        window.onresize = function () {
          adjust()
        }
      }
      const adjust = function () {
        let winWidth = document.documentElement.clientWidth
        let body = document.getElementsByTagName('body')[0]
        let scale = winWidth / 1920
        body.style.cssText = 'transform:scale(' + scale + ');'
      }
    },
    drawOneChart() {
      const chart = this.$echarts.init(document.getElementById('OneChart'))
      chart.setOption({
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 6,
            },
            itemStyle: {
              color: '#3CD2EA'
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [[1, '#28808E']]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            pointer: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              show: false
            },
            data: [this.data?.nm1_rent_area / this.data?.nm1_full_area * 100]
          }
        ]
      })
    },
    drawTwoChart() {
      const chart = this.$echarts.init(document.getElementById('TwoChart'))
      chart.setOption({
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 6,
            },
            itemStyle: {
              color: '#05E1BB'
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [[1, '#05806E']]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            pointer: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              show: false
            },
            data: [this.data?.nm2_rent_area / this.data?.nm2_full_area * 100]
          }
        ]
      })
    },
    drawThreeChart() {
      const chart = this.$echarts.init(document.getElementById('ThreeChart'))
      chart.setOption({
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 6,
            },
            itemStyle: {
              color: '#E8B006'
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [[1, '#756818']]
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            pointer: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              show: false
            },
            data: [this.data?.nm3_rent_area / this.data?.nm3_full_area * 100]
          }
        ]
      })
    },
    getData() {
      getCompanyList({
        type: 5,
        limit: 999
      }).then((res) => {
        console.log('获取企业列表数据', res)
        const { data } = res;
        data.map((item) => {
          const company_industry = [];
          item.company_keywords = item.company_keywords ? JSON.parse(item.company_keywords) : [];
          item.patent_star = item.patent_star ? JSON.parse(item.patent_star) : null;
          item.intellectual_property_statistics = item.intellectual_property_statistics ? JSON.parse(item.intellectual_property_statistics) : null
          item.rd_efficiency = item.rd_efficiency ? JSON.parse(item.rd_efficiency) : null
          item.distribution = item.distribution ? JSON.parse(item.distribution) : []
          item.company_industry = item.company_industry ? JSON.parse(item.company_industry) : []
          item.company_industry.map((item) => {
            if (item.children && item.children.length > 0) {
              item.children.map((jtem) => {
                company_industry.push({
                  value: jtem.industry_id,
                  label: jtem.industry_name
                })
              })
            }
          })
          item.company_industry = company_industry
          item.grade = item.grade ? JSON.parse(item.grade) : []
          item.tech_rd_strength = item.tech_rd_strength ? JSON.parse(item.tech_rd_strength) : []
        })
        this.list = data
      })
    },
    onItem(item, index) {
      this.itemData = item;
      this.visible = false
      setTimeout(() => {
        this.visible = true
      }, 100);
    },
    getHaiXingData() {
      getHaiXingData().then((res) => {
        const { data } = res;
        this.data = data;
        this.drawOneChart()
        this.drawTwoChart()
        this.drawThreeChart()
      })
    }
  },
  mounted() {
    this.getData()
    this.initScale()
    this.getHaiXingData()
  },
  beforeDestory() {

  }
}
</script>


<style lang="less">
.haixing {
  .haixing-wrap {
    z-index: 10;
    position: absolute;
    width: 420px;
    height: 100%;
  }

  .haixing-left-wrap {
    left: 30px;
  }

  .haixing-right-wrap {
    right: 30px;
  }

  .haixing-item {
    z-index: 10;
    position: absolute;
    background: rgba(0, 31, 36, 0.7);

    .haixing-item-title-wrap {
      position: absolute;
      width: 100%;
      height: 38px;

      .haixing-item-title {
        display: flex;
        align-items: center;
        height: 34px;

        .haixing-item-title-icon {
          width: 26px;
          height: 26px;
          margin: 0 10px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url(../assets/images/haixing/title-icon.png);
        }

        .haixing-item-title-name {
          font-size: 20px;
          font-weight: 500;
          color: #3EEDED;
        }
      }

      .haixing-item-title-img {
        position: absolute;
        width: 398px;
        height: 4px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/images/haixing/title.png);
      }
    }

    .haixing-item-bottom-line {
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      background: linear-gradient(90deg, transparent 0%, #00D9E0 100%);
    }

    .haixing-item-content-data {
      position: absolute;
      top: 38px;
    }
  }

  .haixing-item-01 {
    width: 420px;
    height: 361px;
    top: 91px;

    .haixing-item-content-data {
      margin: 20px;

      .haixing-item-content-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .haixing-item-content-item {
          display: flex;
          align-items: center;
          min-width: 190px;
          margin-bottom: 14px;

          &:nth-child(1) {
            .haixing-item-content-item-icon {
              background-image: url(../assets/images/haixing/item-01-icon-01.png);
            }

            .haixing-item-content-item-value {
              color: #3CD2EA;
            }
          }

          &:nth-child(2) {
            .haixing-item-content-item-icon {
              background-image: url(../assets/images/haixing/item-01-icon-02.png);
            }

            .haixing-item-content-item-value {
              color: #05E1BB;
            }
          }

          &:nth-child(3) {
            .haixing-item-content-item-icon {
              background-image: url(../assets/images/haixing/item-01-icon-03.png);
            }

            .haixing-item-content-item-value {
              color: #E8B006;
            }
          }

          &:nth-child(4) {
            .haixing-item-content-item-icon {
              background-image: url(../assets/images/haixing/item-01-icon-04.png);
            }

            .haixing-item-content-item-value {
              color: #FE8D3D;
            }
          }

          .haixing-item-content-item-icon {
            width: 44px;
            height: 44px;
            margin-right: 12px;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          .haixing-item-content-item-data {
            display: flex;
            flex-direction: column;
            align-items: center;

            .haixing-item-content-item-name {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
            }

            .haixing-item-content-item-value {
              font-size: 30px;
              font-weight: bold;
              font-family: 'bigdsfont';
            }
          }
        }
      }

      .haixing-item-content-bottom {
        .haixing-item-content-bottom-title {
          font-size: 22px;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 8px;
        }

        .haixing-item-content-bottom-describe {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          opacity: 0.9;
          margin-bottom: 12px;

          &:last-child(2) {
            margin-bottom: 0;
          }
        }

        .haixing-item-content-bottom-subtitle {
          font-size: 18px;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: 10px;
        }
      }
    }
  }

  .haixing-item-02 {
    width: 420px;
    height: 578px;
    top: 472px;

    .haixing-item-content-data {
      margin: 20px;

      .haixing-item-content-data-wrap {
        margin-bottom: 16px;

        .haixing-item-content-data-item {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          &:nth-child(1) {
            .haixing-item-content-data-icon {
              background-image: url(../assets/images/haixing/item-02-icon-01.png);
            }
          }

          &:nth-child(2) {
            .haixing-item-content-data-icon {
              background-image: url(../assets/images/haixing/item-02-icon-02.png);
            }
          }

          &:nth-child(3) {
            .haixing-item-content-data-icon {
              background-image: url(../assets/images/haixing/item-02-icon-03.png);
            }
          }

          &:nth-child(4) {
            .haixing-item-content-data-icon {
              background-image: url(../assets/images/haixing/item-02-icon-04.png);
            }
          }

          &:nth-child(5) {
            .haixing-item-content-data-icon {
              background-image: url(../assets/images/haixing/item-02-icon-05.png);
            }
          }

          &:nth-child(6) {
            .haixing-item-content-data-icon {
              background-image: url(../assets/images/haixing/item-02-icon-06.png);
            }
          }

          .haixing-item-content-data-icon {
            width: 42px;
            height: 42px;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          .haixing-item-content-data-text {
            color: #FFFFFF;
            font-size: 18px;
            margin-left: 12px;

            b {}

            span {}
          }
        }
      }

      .haixing-item-content-data-img {
        width: 380px;
        height: 210px;
        background-image: url(../assets/images/haixing/item-02-img.png);
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .haixing-item-03 {
    width: 420px;
    height: 361px;
    top: 91px;

    .haixing-item-content-data {
      margin: 5px 0;

      .haixing-item-content-top {
        display: flex;
        justify-content: center;
        align-items: center;

        .haixing-item-content-top-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:nth-child(1) {

            .haixing-item-content-top-item-chart-data-value {
              color: #3CD2EA;
            }

            .haixing-item-content-top-item-chart-line {
              background-image: url(../assets/images/haixing/item-03-light-01.png);
            }
          }

          &:nth-child(2) {

            .haixing-item-content-top-item-chart-data-value {
              color: #05E1BB;
            }

            .haixing-item-content-top-item-chart-line {
              background-image: url(../assets/images/haixing/item-03-light-02.png);
            }
          }

          &:nth-child(3) {

            .haixing-item-content-top-item-chart-data-value {
              color: #E8B006;
            }

            .haixing-item-content-top-item-chart-line {
              background-image: url(../assets/images/haixing/item-03-light-03.png);
            }
          }

          .haixing-item-content-top-item-chart-data {
            width: 90px;
            height: 78px;
            top: 14px;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .haixing-item-content-top-item-chart-data-value {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 6px;

              span {
                font-weight: bold;
                font-family: 'bigdsfont';
                font-size: 30px;
              }
            }

            .haixing-item-content-top-item-chart-data-value-name {
              color: #ffffff;
            }
          }

          .haixing-item-content-top-item-chart-name {
            position: absolute;
            bottom: 0;
            font-size: 18px;
            margin-bottom: 6px;
            font-weight: 500;
            color: #FFFFFF;
          }

          .haixing-item-content-top-item-chart-line {
            width: 72px;
            height: 7px;
            background-size: 100%;
            background-repeat: no-repeat;
          }
        }
      }

      .haixing-item-content-bottom {
        margin-top: 20px;

        .haixing-item-content-bottom-title {
          display: flex;
          align-items: center;
          width: 420px;
          height: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          background: rgba(100, 230, 201, 0.2);

          span {
            &:nth-child(1) {
              margin-left: 33px;
            }

            &:nth-child(2) {
              margin-left: 63px;
            }

            &:nth-child(3) {
              margin-left: 35px;
            }

            &:nth-child(4) {
              margin-left: 36px;
            }
          }
        }

        .haixing-item-content-bottom-content {

          .haixing-item-content-bottom-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 24px;
            margin-top: 19px;

            &:nth-child(1) {
              span {
                &:nth-child(3) {
                  color: #3CD2EA;
                }
              }
            }

            &:nth-child(2) {
              span {
                &:nth-child(3) {
                  color: #05E1BB;
                }
              }
            }

            &:nth-child(3) {
              span {
                &:nth-child(3) {
                  color: #E8B006;
                }
              }
            }

            span {
              color: #ffffff;

              &:nth-child(4) {
                color: #F1502B
              }
            }
          }
        }
      }
    }
  }

  .haixing-item-04 {
    width: 420px;
    height: 574px;
    top: 472px;

    .haixing-item-content {
      margin-top: 38px;
      height: 535px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .haixing-item-content-data {
        position: relative;
        top: 0;
        margin: 20px;

        .haixing-item-content-data-item {
          display: flex;
          align-items: center;
          width: 380px;
          height: 40px;
          background: rgba(5, 49, 107, 0.4);
          margin-bottom: 10px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          .haixing-item-content-data-icon {
            width: 30px;
            height: 30px;
            margin-left: 12px;
            margin-right: 6px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url(../assets/images/haixing/item-04-icon-n.png);
          }

          .haixing-item-content-data-name {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            transition: all .3s;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .haixing-item-content-data .haixing-item-content-data-active {
        width: 380px;
        height: 40px;
        background: rgba(8, 91, 169, 0.7);
        border: 1px solid #0ED4FF;

        .haixing-item-content-data-icon {
          background-image: url(../assets/images/haixing/item-04-icon-a.png);
        }

        .haixing-item-content-data-name {
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          transition: all .3s;
        }
      }

      .haixing-nodata {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #ffffff;
      }
    }
  }
}
</style>